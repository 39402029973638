import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const budapestcomiccon1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Tudtuk, hogy a neves múltú Comic Con magyar piacon megjelenése magas minőséget kíván. Ezért alakítottunk ki nekik olyan webes felületet, amelyet szabadon személyre tudnak szabni a rendezvény folyamatosan bővülő kínálatához.",
  displayXo: true,
  reverse: true,
  title: "Nemzetközi név, magyar piacon",
  picture: "/images/refs/budapestcomiccon/budapestcomiccon_1.png",
  boldDescription: ''
}

const budapestcomiccon2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "Elsődleges szempont volt, hogy a látogató egy mobilon jól átlátható, könnyen navigálható weboldalt kapjanak. Ennek tartalmát pedig az egyedi admin felületen tudják a szerkesztők folyamatosan aktualizálni.",
  displayXo: true,
  reverse: false,
  title: "Mobil élmény",
  picture: "/images/refs/budapestcomiccon/budapestcomiccon_2.png",
  boldDescription: ''
}

export const budapestcomicconInfoCards = [
  budapestcomiccon1,
  budapestcomiccon2,
]

