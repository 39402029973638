import { Reference } from "../reference-interface"
import { budapestcomicconInfoCards } from "../../service-info-card-interface/used_by/budapestcomiccon-cards"

export const PlayIt : Reference = {
  monitorPic: "/images/refs/budapestcomiccon/budapestcomiccon_monitor.png",
  siteName: "Budapest Comic Con",
  siteDescription: "A Comic Con egy pop kulturális, műfajokon átívelő, de azokat mégis összekötő hatalmas rendezvény. Nekik fejlesztettük le hivatalos weboldalukat.",
  siteUrl: "https://www.budapestcomiccon.hu/",
  cards: budapestcomicconInfoCards,
  referencePics: [
    "/images/refs/budapestcomiccon/budapestcomiccon_ref.png",
    "/images/refs/budapestcomiccon/budapestcomiccon_ref_color.png",
    "/images/refs/budapestcomiccon/budapestcomiccon_3.png",
  ],
  pageFunctions: [
    "Egyedi design",
    "Önálló adminfelület",
  ],
  reversePageFunctions: false,
}
